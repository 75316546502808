// Your variable overrides
$body-bg: #fff;
$body-color: #111;

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import url('./font.scss');
@import url('./customerVariables.scss');
@import url('./theme.scss');
@import url('./media.scss');

html {
  font-family: 'IBM Plex Sans', sans-serif !important;
}
body {
  padding: 0px !important;
}
